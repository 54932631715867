<template>
  <div>
    <h2 v-html="$t('search_mhw_title')"></h2>
    <!-- Betrieb -->
    <v-select
      v-if="getFarms.length && SearchMilkQualityBenchmarkStore.farmId"
      :rules="[$rules.required]"
      id="search_mhw_farm_id"
      v-model="SearchMilkQualityBenchmarkStore.farmId"
      :label="$t('search_mhw_agis_id')"
      :items="getFarms"
      item-title="agisId"
      item-value="id"
      @keyup.enter="search"
      @change="setPostcode"
    />
    <!-- Von -->
    <DbmMonthPicker
      name="search_mhw_valid_from"
      id="search_mhw_valid_from"
      :rules="[$rules.isCHMonth, $rules.required]"
      v-model="SearchMilkQualityBenchmarkStore.from"
      :dateLabel="$t('search_mhw_valid_from')"
    />
    <!-- Bis -->
    <DbmMonthPicker
      name="search_mhw_valid_until"
      id="search_mhw_valid_until"
      :rules="[$rules.isCHMonth, $rules.required]"
      v-model="SearchMilkQualityBenchmarkStore.until"
      :dateLabel="$t('search_mhw_valid_until')"
      returnType="firstOfMonth"
    />
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import { apiURL } from '@/main'
import { showError } from '@/services/axios'
import DbmMonthPicker from '@/components/dbmMonthPicker.vue'
import { useSearchMilkQualityBenchmarkStore } from '@/store/SearchMilkQualityBenchmarkStore'

import { useOwnManagedFarmsStore } from '@/store/OwnManagedFarmsStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchMilkQualityBenchmark',
  extends: baseSearchVue,
  data() {
    return {
      farms: []
    }
  },
  computed: {
    getFarms() {
      return useOwnManagedFarmsStore().farms
    },
    SearchMilkQualityBenchmarkStore() {
      return useSearchMilkQualityBenchmarkStore()
    }
  },
  methods: {
    reset() {
      this.resetFields(this.SearchMilkQualityBenchmarkStore)
      this.setDefaultFarmId()
    },
    setDefaultFarmId() {
      if (this.SearchMilkQualityBenchmarkStore.farmId === null) this.SearchMilkQualityBenchmarkStore.farmId = this.getFarms[0]?.id
      this.setPostcode()
    },
    async setPostcode() {
      if (null != this.SearchMilkQualityBenchmarkStore.farmId) {
        try {
          const response = await this.axios.get(apiURL + '/farms/' + this.SearchMilkQualityBenchmarkStore.farmId + '/postcodeCluster')
          this.SearchMilkQualityBenchmarkStore.postcodeCluster = await response.data
        } catch (e) {
          this.SearchMilkQualityBenchmarkStore.postcodeCluster = null
          showError(e)
        }
      } else {
        this.SearchMilkQualityBenchmarkStore.postcodeCluster = null
      }
    }
  },
  watch: {
    getFarms: {
      handler(value) {
        if (value.length) {
          console.log('reset farms')
          this.setDefaultFarmId()
        }
      },
      deep: true
    }
  },
  components: {
    DbmMonthPicker: DbmMonthPicker
  },
  mounted() {
    useOwnManagedFarmsStore().fetchOwnManagedFarms()
    if (this.getFarms.length) {
      this.setDefaultFarmId()
    }
    if (this.$route.query?.farmId) {
      this.SearchMilkQualityBenchmarkStore.farmId = this.$route.query?.farmId
      this.setPostcode()
    }
    if (this.$route.query?.from) {
      this.SearchMilkQualityBenchmarkStore.from = this.$route.query?.from
    }
    if (this.$route.query?.until) {
      this.SearchMilkQualityBenchmarkStore.until = this.$route.query?.until
    }
  }
})
</script>
