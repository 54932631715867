import { format, addYears, startOfMonth, endOfMonth } from 'date-fns'
import { defineStore } from 'pinia'
import { TermItemDefinition } from '@/services/term'

export class PostcodeCluster {
  codes = ''
  farmCount = 0
  id = 0
  validFrom = ''
}

export const useSearchMilkQualityBenchmarkStore = defineStore('searchMilkQualityBenchmarkStore', {
  state: () => ({
    farmId: null as number | null,
    from: format(startOfMonth(addYears(new Date(), -2)), 'yyyy-MM-dd') as string | null,
    until: format(endOfMonth(new Date()), 'yyyy-MM-dd') as string | null,
    postcodeCluster: null as null | PostcodeCluster,
    items: [
      { key: 'farmId', operation: ':' },
      { key: 'from', operation: '>=' },
      { key: 'until', operation: '<=' }
    ] as TermItemDefinition[]
  })
})
