export class Farm {
  id = ''
  agisId = 0
  name = ''
}
import axios from 'axios'
import { showError } from '@/services/axios'
import { useLegalEntityStore } from './LegalEntityStore'
import { apiURL } from '@/main'
import { defineStore } from 'pinia'

export const useOwnManagedFarmsStore = defineStore('OwnManagedFarmsStore', {
  state: () => ({
    farms: [] as Farm[]
  }),
  actions: {
    resetOwnManagedFarms() {
      this.farms = []
      return this.farms
    },
    async fetchOwnManagedFarms() {
      console.log('fetching farms')
      if (this.farms.length === 0) {
        try {
          const legalEntityStore = useLegalEntityStore()
          const response = await axios.get(apiURL + '/legalEntities/' + legalEntityStore.getSelectedPersonId + '/farms')
          this.farms = response.data
          return this.farms
        } catch (e) {
          showError(e)
        }
      } else {
        console.log('abort farm already loaded')
        return this.farms
      }
    }
  }
})
